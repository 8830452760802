<template>
  <div class="confirm-container">
    <div class="main-section">
      <order-process :step="1"></order-process>

      <div class="row-item">
        <span class="label">订单编号: </span>
        <span class="cont-box">{{orderData.order_no}}</span>
      </div>
      <div class="row-item">
        <span class="label">项目名称: </span>
        <span class="cont-box">{{orderData.project_name}}</span>
      </div>
      <div class="row-item">
        <span class="label">所属公司: </span>
        <span class="cont-box">{{orderData.creator_company_name}}</span>
      </div>
      <div class="row-item">
        <span class="label">班组名称: </span>
        <span class="cont-box">{{orderData.team_name}}</span>
      </div>
      <div class="row-item">
        <span class="label">购买成员数量: </span>
        <span class="cont-box">{{orderData.goods_quantity}}</span>
      </div>
      <div class="row-item">
        <span class="label">单价: </span>
        <span class="cont-box">{{orderData.goods_price}} 元/人</span>
      </div>

      <div class="btn-area">
        <Button
          class="btn-download"
          type="success"
          icon="ios-download-outline"
          size="large"
          @click="conformOrder"
        >
          购买合同下载
        </Button>
      </div>
      
      <div class="total-box">
        <label>总计：<span>¥{{ totalPrice }}</span></label>
      </div>

      <div class="upload-box">
        <div>
          <Upload
            class="upload-item"
            type="drag"
            name="uploadFile"
            action="/rlapi/common/uploadPic"
            :on-success="uploadContractSuccess"
            :on-error="uploadError"
            :show-upload-list="false"
          >
            <div class="upload-bg">
              <div v-if="previewFirst">
                <img :src="previewFirst" alt="合同凭证" />
              </div>
              <div v-else>
                <Icon type="ios-cloud-upload" size="52" style="color: #AFAEB1; margin-top: 55px"></Icon>
                <p>拖放或点击上传合同凭证</p>
              </div>
            </div>
          </Upload>
          <div class="upload-tips">请下载购买合同后盖章并上传</div>
        </div>
        <div>
          <Upload
            class="upload-item"
            type="drag"
            name="uploadFile"
            action="/rlapi/common/uploadPic"
            :on-success="uploadPaySuccess"
            :on-error="uploadError"
            :show-upload-list="false"
            :on-preview="previewPic"
          >
            <div class="upload-bg">
              <div v-if="previewSecond">
                <img :src="previewSecond" alt="支付凭证" />
              </div>
              <div v-else>
                <Icon type="ios-cloud-upload" size="52" style="color: #AFAEB1; margin-top: 55px"></Icon>
                <p>拖放或点击上传支付凭证</p>
              </div>
            </div>
          </Upload>
          <div class="upload-tips">请支付完成后上传支付凭证</div>
        </div>
        </div>

      <div class="purchase-button">
        <Button
          class="login_buttonBg order-button"
          style="width: 140px;height: 45px; margin-top: 28px;"
          type="primary"
          @click="conformOrder">
          确认提交
        </Button>
      </div>
    </div>

  </div>
</template>

<script>
  import OrderProcess from "@/components/orderProcess"

  export default {
    name: "billConform",
    components: {
      OrderProcess
    },
    data() {
      return {
        tableHeight: null,
        orderData: {
          order_no: "2019071919091200000",
          creator_company_name: "广州富力集团",
          goods_quantity: 10,
          validity_time: "2019-07-17 至 2020-07",
          phone: "13800138000",
          goods_price: '78',
          order_amount: 0,
          id: 10,
          contract_attachment_id: "",
          pay_attachment_id: ""
        },
        previewFirst: null,
        previewSecond: null,
        orderColumns: [
          {
            title: '订单编号',
            minWidth: 120,
            key: "order_no",
            align: 'center'
          },
          {
            title: '项目名称',
            minWidth: 120,
            key: "project_name",
            align: 'center'
          },
          {
            title: '所属公司',
            minWidth: 120,
            key: "corp_name",
            align: 'center'
          },
          {
            title: '班组名称',
            minWidth: 120,
            key: "team_name",
            align: 'center'
          },
          {
            title: '购买成员数量',
            minWidth: 120,
            key: "goods_quantity",
            align: 'center'
          },
          {
            title: '有效时间',
            minWidth: 120,
            key: "validity_time",
            align: 'center'
          },
          {
            title: '单价',
            minWidth: 120,
            key: "goods_price",
            align: 'center'
          },
          {
            title: '手机号码',
            minWidth: 120,
            key: "phone",
            align: 'center'
          },
        ],
        totalPrice: 1500.00,
        purchaseForm:{},
      }
    },
    computed: {
      // totalPrice
    },
    methods: {
      previewPic(file, response) {

      },
      uploadContractSuccess(response, file, fileList) {
        if (response.success) {
            this.orderData.contract_attachment_id = response.data.attachment_id;
            this.previewFirst = response.data.url
        } else {
          this.$Message.error(response.msg);
        }
      },
      uploadPaySuccess(response, file, fileList) {
        if (response.success) {
          this.orderData.pay_attachment_id = response.data.attachment_id;
          this.previewSecond = response.data.url
        } else {
          this.$Message.error(response.msg);
        }
      },
      uploadError(response, file, fileList) {
        this.$Message.error(response.msg);
      },
      async conformOrder() {
        let token = this.common.getAuth();
        let param = {
          "order_id" : this.orderData.id,
          "contract_attachment_id": this.orderData.contract_attachment_id,
          "pay_attachment_id": this.orderData.pay_attachment_id
        };
        let res = await this.$fetch("/api/api/buyer/order/payOrder", {
          method:"post",
          data: param
        },{
          "headers": {
            "Eova-Authorization": token
          }
        });
        if (res) {
          console.log(res);
          
          if (res.code == "200") {
            // this.$Message.success("确认成功");
            this.$router.push({ name: 'successOrder'});
          } else {
            // eslint-disable-next-line no-console
            this.$Message.error(res.msg || '下单失败， 请稍后再试');
            this.$Message.error(res.data.message);
            if (res.data.code === "6000") {
              this.$router.push({name:"login"});
            }
          }
        }
      },
      getOrderData(id) {
        this.purchaseForm = JSON.parse(this.$route.query.purchaseForm);
        let token = this.common.getAuth();
        this.$axios.post(`${this.apiUrlCros}/api/api/buyer/order/detail`,{
          creator_id: this.purchaseForm.creator_id,
          order_id: id
        },{
          "headers": {
            "Eova-Authorization": token
          }
        }).then(res => {
          if (res.data.code == "200") {
            let order = res.data.resData;
            let order_item = order.orderItems[0];
            let user = this.common.getStorage(this.CONSTANTS.USER_INFO);
            this.orderData = res.data.resData;
            this.orderData.goods_price = order_item.goods_price;
            this.orderData.goods_quantity = order_item.goods_quantity;
            this.totalPrice = order.order_amount;
          } else {
            this.$Message.error(res.data.message);
            if (res.data.code === "6000") {
              this.$router.push({name:"login"});
            }
          }
        }).catch(err => {
          this.$Message.error(err.message);
        })
      },
    },
    created() {

    },
    mounted() {
      this.getOrderData(this.$route.query.orderId);
    }
  }
</script>

<style lang="less">

.confirm-container {
  margin-top: 30px;
  padding: 30px;
  background: #fff;
  border-radius: 20px;
  min-height: 600px;
  color: #111;
  .main-section {
    margin: 0 auto;
    width: 786px;
  }
  .row-item {
    margin-top: 30px;
    color: #111;
    font-size: 20px;
    .label {
      margin-right: 16px;
    }
  }
  .btn-area {
    margin: 32px 0;
    text-align: left;
  }
  .total-box {
    padding-bottom: 30px;
    text-align: right;
    color: #2DD3FF;
    font-size: 30px;
    border-bottom: 2px solid #EBEDEE
  }
  .upload-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
    .upload-bg {
      width: 300px;
      height: 200px;
      background: #ECECEE;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .upload-item {
      width: 300px;
    }
    .upload-tips {
      &::before {
        content: '*';
        color: #00C9FF;
      }
    }
  }
  .purchase-button {
    text-align: center;
    button {
      background: #01C8FF;
      border: 1px solid #01C8FF;
    }
  }
}
</style>
